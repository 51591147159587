import { graphql, useStaticQuery } from 'gatsby';
import React, { FC, HTMLAttributes } from 'react';
import styled, { keyframes, css } from 'styled-components';
import Title from './Title';
import { UnreachableCaseError } from '../entities/UnreachableCaseError';
import { useIntl } from 'gatsby-plugin-intl';

type WorldPosition = 'right' | 'left' | 'bottom' | 'center';
export type WorldTitle = 'about_me' | 'archive' | 'upcoming';

interface Props extends HTMLAttributes<HTMLDivElement> {
    position: WorldPosition;
    title: WorldTitle;
    hasMarginMobile?: boolean;
    link?: string;
}

interface DataProps {
    worldTitles: {
        edges: [
            {
                node: {
                    childMarkdownRemark: {
                        frontmatter: {
                            [key: string]: {
                                about_me: string;
                                upcoming: string;
                                archive: string;
                            };
                        };
                    };
                };
            }
        ];
    };
}

const World: FC<Props> = ({
    title,
    position,
    hasMarginMobile = false,
    ...props
}) => {
    const data = useStaticQuery<DataProps>(query);
    const { locale } = useIntl();
    const worldTitle =
        data.worldTitles.edges[0].node.childMarkdownRemark.frontmatter[
            `${locale}Worlds`
        ][title];

    return (
        <Container
            position={position}
            hasMarginMobile={hasMarginMobile}
            {...props}
        >
            {position !== 'center' && (
                <>
                    <Pulse />
                    <Hover />
                </>
            )}
            <TitleContainer position={position}>
                <Title variant="world">{worldTitle}</Title>
            </TitleContainer>
        </Container>
    );
};

const query = graphql`
    {
        worldTitles: allFile(
            filter: { absolutePath: { regex: "/world-titles/i" } }
        ) {
            edges {
                node {
                    childMarkdownRemark {
                        frontmatter {
                            nlWorlds {
                                about_me
                                upcoming
                                archive
                            }
                            enWorlds {
                                about_me
                                upcoming
                                archive
                            }
                        }
                    }
                }
            }
        }
    }
`;

const pulseAnimation = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}`;

const getCssPositionOfTitle = (position: WorldPosition) => {
    switch (position) {
        case 'right':
        case 'left':
            return css`
                ${position}:0;
                bottom: 50%;
                transform: translate(0, 50%);
            `;
        case 'bottom':
            return css`
                left: 50%;
                bottom: 0;
                max-width: 100px;
                transform: translate(-50%, 0);
            `;
        case 'center':
            return css`
                left: 50%;
                bottom: 50%;
                transform: translate(-50%, 50%);
            `;
        default:
            throw new UnreachableCaseError(position);
    }
};

const Pulse = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.backgroundColors.worldPulse};
    opacity: 0;
    animation: 6s ${pulseAnimation};
    animation-iteration-count: infinite;
    z-index: ${({ theme }) => theme.zIndices.hide};
    transition: opacity 0.5s;
`;

const Hover = styled.div`
    opacity: 1;
    transition: all 1s;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: ${({ theme }) => theme.zIndices.hide};
        background: ${({ theme }) => theme.backgroundColors.worldPulse};
        transition: all 1s;
        opacity: 0;
    }
`;

const TitleContainer = styled.div<Pick<Props, 'position'>>`
    position: absolute;
    color: ${({ theme }) => theme.colors.black};
    text-align: center;
    padding: 0 9px;
    transition: all 0.8s;
    ${({ position }) => getCssPositionOfTitle(position)};
`;

const Container = styled.div<Pick<Props, 'position' | 'hasMarginMobile'>>`
    position: relative;
    margin: ${({ hasMarginMobile }) => (hasMarginMobile ? '45px' : '0')} auto 0
        auto;
    border-radius: 50%;
    min-width: 316px;
    min-height: 316px;
    max-width: 316px;
    max-height: 316px;
    border: ${({ theme }) => theme.border};
    overflow: hidden;
    cursor: ${({ position }) =>
        position !== 'center' ? 'pointer' : 'default'};

    ${({ position }) =>
        position !== 'center' &&
        css<Pick<Props, 'position'>>`
            &:hover ${Hover}, &:active ${Hover} {
                &::before {
                    opacity: 1;
                }
            }

            &:hover ${Pulse} {
                animation: unset;
            }

            @media (min-width: ${({ theme }) => theme.mediaQueries.large}) {
                &:hover {
                    border-width: 2px;
                }
                &:hover ${TitleContainer}, &:active ${TitleContainer} {
                    letter-spacing: ${({ theme }) =>
                        theme.letterSpacings.expanded};
                    transform: translate(
                        ${({ position }) => position !== 'right' && '-'}50%,
                        50%
                    );
                    ${({ position }) =>
                        position === 'bottom' &&
                        css`
                            bottom: 50%;
                            max-width: 256px;
                            left: calc(50% + 10.5px);
                        `};
                    ${({ position }) =>
                        position === 'right' && 'right:calc(50% - 10.5px)'}
                    ${({ position }) =>
                        position === 'left' && 'left:calc(50% + 10.5px)'}
                }
            }
        `}

    @media (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        margin: 0;
        position: absolute;
        ${({ position }) => getCssPositionOfTitle(position)};
    }
`;

export default World;
