import { FormattedDate, FormattedMessage } from 'gatsby-plugin-intl';
import React, { FC } from 'react';
import styled from 'styled-components';
import { ShowInfo, UpcomingInfo } from '../graphQL/showData';
import Body from './Body';

type Props = ShowInfo &
    Omit<UpcomingInfo, 'photo_url' | 'photo_alt' | 'video_url' | 'video_title'>;

const ShowDetails: FC<Props> = (showDetails) => {
    const {
        startDate,
        endDate,
        title,
        curator,
        facebook_url,
        facebook_url_display,
        website_url,
        website_url_display,
        article_url,
        article_url_display,
        info,
        sponsor,
    } = showDetails;
    const end = endDate && new Date(endDate);

    return (
        <>
            {end && (
                <InfoBody>
                    {startDate && `${new Date(startDate).getDate()} - `}
                    {end.getDate()} <FormattedDate value={end} month="long" />{' '}
                    {end.getUTCFullYear()}
                </InfoBody>
            )}
            <InfoBody>{title}</InfoBody>
            {curator && (
                <InfoBody>
                    <FormattedMessage id="curator" /> {curator}
                </InfoBody>
            )}
            {facebook_url && (
                <InfoBody>
                    <FormattedMessage id="fb" />:{' '}
                    <a href={facebook_url}>
                        {facebook_url_display ?? facebook_url}
                    </a>
                </InfoBody>
            )}
            {website_url && (
                <InfoBody>
                    Web:{' '}
                    <a href={website_url}>
                        {website_url_display ?? website_url}
                    </a>
                </InfoBody>
            )}
            {article_url && (
                <InfoBody>
                    <FormattedMessage id="article" />:{' '}
                    <a href={article_url}>
                        {article_url_display ?? article_url}
                    </a>
                </InfoBody>
            )}
            {info && <InfoBody>Info: {info}</InfoBody>}
            {sponsor && (
                <InfoBody>
                    <FormattedMessage id="sponsor" /> <span>{sponsor}</span>
                </InfoBody>
            )}
        </>
    );
};

const InfoBody = styled(Body).attrs({ size: 'tiny' })`
    color: ${({ theme }) => theme.colors.black2};
    font-size: 10px;
`;

export default ShowDetails;
