import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React, { FC } from 'react';
import UpcomingWorld, { CurrentUpcoming } from './upcoming-world';
import { UpcomingInfo } from '../graphQL/showData';

type Props = {
    data: {
        allMarkdownRemark: {
            edges: [
                {
                    node: {
                        frontmatter: {
                            genericUpcoming: UpcomingInfo;
                            nlUpcoming: {
                                description: string;
                                info: string;
                            };
                            enUpcoming: {
                                description: string;
                                info: string;
                            };
                        };
                        fields: {
                            slug: string;
                        };
                    };
                }
            ];
        };
    };
    isModalOpen: boolean;
    currentUpcoming: CurrentUpcoming;
};

const Upcoming: FC<Props> = ({ data }) => {
    const { locale } = useIntl();
    const pathname =
        typeof window !== 'undefined' ? window.location.pathname : '';
    const currentSlug = pathname.split(`/${locale}`).pop();
    const currentUpcoming = data.allMarkdownRemark.edges.find(
        (item) => item.node.fields.slug === currentSlug
    ) ?? {
        node: {
            frontmatter: {
                genericUpcoming: {
                    title: '',
                    photo: '',
                    photo_alt: '',
                },
                enUpcoming: {
                    description: '',
                    info: '',
                },
                nlUpcoming: {
                    description: '',
                    info: '',
                },
            },
        },
    };

    return (
        <UpcomingWorld
            data={data}
            currentUpcoming={currentUpcoming.node.frontmatter}
        />
    );
};

export const query = graphql`
    query upcoming {
        allMarkdownRemark(
            filter: {
                frontmatter: { genericUpcoming: { title: { ne: null } } }
            }
            limit: 6
        ) {
            edges {
                node {
                    frontmatter {
                        genericUpcoming {
                            title
                            photo
                            photo_alt
                            video_url
                            video_title
                            video_slug
                            location
                            startDate
                            endDate
                            curator
                            facebook_url
                            facebook_url_display
                            website_url
                            website_url_display
                            article_url
                            article_url_display
                            sponsor
                        }
                        nlUpcoming {
                            description
                            info
                        }
                        enUpcoming {
                            description
                            info
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`;

export default Upcoming;
