import { graphql, navigate } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import Body from '../components/Body';
import Img from '../components/Img';
import Layout from '../components/Layout';
import Modal from '../components/Modal';
import ShowDetails from '../components/ShowDetails';
import Window from '../components/Window';
import World from '../components/World';
import { heightMargin, marginTop } from '../constants/ContentStyle';
import { ShowInfo, UpcomingInfo } from '../graphQL/showData';
import useModal from '../hooks/useModal';
import useWindowSize from '../hooks/useWindowSize';
import Markdown from 'markdown-to-jsx';

export type CurrentUpcoming = {
    genericUpcoming: UpcomingInfo;
    nlUpcoming: {
        description: string;
        info: string;
    };
    enUpcoming: {
        description: string;
        info: string;
    };
};

type Props = {
    data: {
        allMarkdownRemark: {
            edges: [
                {
                    node: {
                        frontmatter: {
                            genericUpcoming: UpcomingInfo;
                            nlUpcoming: {
                                description: string;
                                info: string;
                            };
                            enUpcoming: {
                                description: string;
                                info: string;
                            };
                        };
                        fields: {
                            slug: string;
                        };
                    };
                }
            ];
        };
    };
    currentUpcoming: CurrentUpcoming;
};

const UpcomingWorld: FC<Props> = ({ data, currentUpcoming }) => {
    const { width } = useWindowSize();
    const { locale } = useIntl();
    const { isOpen, toggleModal } = useModal();
    const [windowHover, setWindowHover] = useState<number | undefined>();

    const { genericUpcoming, nlUpcoming, enUpcoming } = currentUpcoming ?? {
        genericUpcoming: {},
        enUpcoming: {},
        nlUpcoming: {},
    };

    const localizedDescription =
        locale === 'nl' ? nlUpcoming.description : enUpcoming.description;
    const localizedInfo = locale === 'nl' ? nlUpcoming.info : enUpcoming.info;

    const upcomingInfo: ShowInfo = {
        ...genericUpcoming,
        info: localizedInfo,
    };

    return (
        <Layout overflowYHidden>
            {width && width > 766 && (
                <WorldContainer>
                    <WorldPosition position="center" title="upcoming" />
                </WorldContainer>
            )}
            <Container>
                {(!currentUpcoming ||
                    (currentUpcoming && width && width > 766)) &&
                    data.allMarkdownRemark.edges.map((edge, idx) => (
                        <React.Fragment key={idx}>
                            <UpcomingWindowContainer
                                idx={idx}
                                onMouseEnter={() => setWindowHover(idx)}
                                onMouseLeave={() => setWindowHover(undefined)}
                                windowHover={windowHover}
                            >
                                <UpcomingWindow
                                    idx={idx}
                                    windowTitle={
                                        edge.node.frontmatter.genericUpcoming
                                            .title
                                    }
                                    noTopBarColor={!!width && width >= 768}
                                    onClick={() =>
                                        navigate(
                                            `/${locale}${edge.node.fields.slug}`
                                        )
                                    }
                                >
                                    {edge.node.frontmatter.genericUpcoming
                                        .photo && (
                                        <Content>
                                            <Image
                                                className="lazyload"
                                                alt={
                                                    edge.node.frontmatter
                                                        .genericUpcoming
                                                        .photo_alt ??
                                                    edge.node.frontmatter
                                                        .genericUpcoming.title
                                                }
                                                imageSrc={
                                                    edge.node.frontmatter
                                                        .genericUpcoming.photo
                                                }
                                            />
                                        </Content>
                                    )}
                                </UpcomingWindow>
                            </UpcomingWindowContainer>
                        </React.Fragment>
                    ))}

                {currentUpcoming && (
                    <Modal
                        modalTitle={currentUpcoming.genericUpcoming.title ?? ''}
                        isOpen={!isOpen}
                        onClose={() => {
                            toggleModal();
                            navigate(`/${locale}/upcoming-world`);
                        }}
                        transparency={0.1}
                        isCentered={false}
                    >
                        <Content inModal>
                            <Info>
                                <ShowDetails {...upcomingInfo} />
                            </Info>
                            {currentUpcoming.genericUpcoming.photo && (
                                <Image
                                    className="lazyload"
                                    imageSrc={
                                        currentUpcoming.genericUpcoming.photo
                                    }
                                    alt={
                                        currentUpcoming.genericUpcoming
                                            .photo_alt ?? ''
                                    }
                                    inModal
                                />
                            )}
                            <Description>
                                <Body>
                                    <Markdown>{localizedDescription}</Markdown>
                                </Body>
                            </Description>
                        </Content>
                    </Modal>
                )}
            </Container>
        </Layout>
    );
};

const WorldPosition = styled(World)`
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
`;

const Container = styled.div`
    ${marginTop}

    @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
        position: absolute;
        left: 20%;
        top: 15%;
    }
`;

const WorldContainer = styled.div`
    ${marginTop};
    position: relative;
    width: 100vw;

    @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
        height: calc(
            100vh -
                ${({ theme }) =>
                    heightMargin(parseInt(theme.mediaQueries.medium))}px
        );
    }
    @media (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        height: calc(
            100vh -
                ${({ theme }) =>
                    heightMargin(parseInt(theme.mediaQueries.large))}px
        );
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        height: calc(
            100vh -
                ${({ theme }) =>
                    heightMargin(parseInt(theme.mediaQueries.xl))}px
        );
    }
`;

const Content = styled.div<{ inModal?: boolean }>`
    padding: 16px 18px;
    ${({ inModal }) =>
        !inModal &&
        css`
            display: flex;
            justify-content: center;
        `}

    @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
        padding: 17px 10px;
        flex-grow: 1;
        position: relative;
    }
`;

const Image = styled(Img)<{ inModal?: boolean }>`
    max-width: 70%;
    border: 1px solid ${({ theme }) => theme.colors.border};
    margin-bottom: ${({ inModal }) => (inModal ? '60px' : '0')};

    @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
        max-width: 50%;
        ${({ inModal }) =>
            inModal
                ? css`
                      max-height: 320px;
                      width: auto;
                      margin-top: 16px;
                  `
                : css`
                      position: absolute;
                      max-height: calc(100% - 34px);
                      width: auto;
                  `};
    }
`;

const UpcomingWindow = styled(Window)<{
    idx: number;
}>`
    background: ${({ theme }) => theme.colors.white};
    margin: 0;
    margin-bottom: 10px;

    @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
        position: relative;
        transition: transform 0.3s ease-out;
        width: 400px;
        height: 252px;
        cursor: pointer;
        display: flex;
        flex-direction: column;

        position: absolute;
        top: 125px;
    }
`;

const UpcomingWindowContainer = styled.div<{
    idx: number;
    windowHover: number | undefined;
}>`
    position: relative;
    @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
        width: 400px;
        height: 377px;
        position: absolute;
        left: ${({ idx }) => 32 * idx}px;
        top: ${({ idx }) => 50 * idx - 125}px;
        pointer-events: ${({ windowHover, idx }) =>
            windowHover === idx || !windowHover ? 'auto' : 'none'};

        :hover ${UpcomingWindow} {
            transform: translateY(-125px);
        }
    }
`;

const Info = styled.div`
    margin-right: 25px;
    flex: 0 0 40%;
    color: ${({ theme }) => theme.colors.black2};
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;

    @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
        margin-bottom: 0;
        flex: 0 0 50%;
    }
`;

const Description = styled.div`
    flex: 1;
`;

export const query = graphql`
    query upcomingOverview {
        allMarkdownRemark(
            filter: {
                frontmatter: { genericUpcoming: { title: { ne: null } } }
            }
            limit: 6
            sort: { fields: fields___publicationDate, order: ASC }
        ) {
            edges {
                node {
                    frontmatter {
                        genericUpcoming {
                            title
                            photo
                            photo_alt
                            video_url
                            video_title
                            video_slug
                            location
                            startDate
                            endDate
                            curator
                            facebook_url
                            facebook_url_display
                            website_url
                            website_url_display
                            article_url
                            article_url_display
                            sponsor
                            publicationDate
                        }
                        nlUpcoming {
                            description
                            info
                        }
                        enUpcoming {
                            description
                            info
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`;

export default UpcomingWorld;
